import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Paper,
} from '@material-ui/core'
import { Close, Error, ArrowBack } from '@material-ui/icons'
import { ButtonHelp, Marker } from '../../components'
import GoogleMapReact from 'google-map-react'

const useStyles = makeStyles({
  paper: {
    width: 'fit-content',
    padding: 16,
    position: 'absolute',
    zIndex: 9999,
    top: '20%',
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'flex',
  },
})

export default function MapDialog({ coordinates, handleClose, isMobile, open, setCoordinates, state }) {
  const classes = useStyles()
  const [custom, setCustom] = useState(coordinates)
  const [openTip, setOpenTip] = useState(true)
  const [warning, setWarning] = useState(false)

  const handleClickMap = ({ lat, lng }) => {
    setCustom({ lat, lng })
  }

  const handleSave = () => {
    setCoordinates(custom)
    setWarning(false)
    handleClose()
  }

  const handleCancel = () => {
    setCustom(coordinates)
    handleClose()
  }

  const handleCloseTip = () => setOpenTip(false)

  const handleOpenWarning = () => setWarning(true)

  const handleCloseWarning = () => setWarning(false)

  useEffect(() => {
    setCustom(coordinates)
  }, [coordinates])

  return (
    <Dialog keepMounted fullScreen open={open} onClose={handleClose} aria-labelledby="dialog-edit">
      {open && (
        <Dialog open={openTip} onClose={handleCloseTip}>
          <DialogTitle>
            <Close style={{ display: 'block', marginLeft: 'auto' }} />
          </DialogTitle>
          <DialogContent style={{ textAlign: 'center' }}>
            <Typography>
              Arraste o marcador verde do mapa até o <br />
              local correto da sua loja.
            </Typography>
            <br />
            <Button color="primary" variant="contained" style={{ minWidth: 225 }} onClick={handleCloseTip}>
              Entendi!
            </Button>
            <br />
            <br />
            <ButtonHelp minWidth={225} />
            <br />
            <br />
          </DialogContent>
        </Dialog>
      )}

      <Dialog open={warning} onClose={handleCloseWarning}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <div>
              <Error style={{ color: '#F9D831', verticalAlign: 'middle' }} /> Atenção
            </div>
            <IconButton onClick={handleCloseWarning}>
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Verifique se o local indicado no mapa está correto. <br />
            <br />
            Indicar o local exato da sua Loja ajuda o Google a validar seu <br />
            cadastro sem nenhum problema.
            <br />
            <br />
            Deseja continuar?
          </Typography>
          <br />
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: isMobile ? 0 : 8, marginBottom: isMobile ? 8 : 0 }}
            onClick={handleSave}
            fullWidth={isMobile}
          >
            Sim, continuar
          </Button>
          <ButtonHelp minWidth={isMobile ? '100%' : 225} />
          <br />
          <br />
        </DialogContent>
      </Dialog>

      <DialogContent style={{ position: 'relative', padding: 0 }}>
        <Paper elevation={0} className={classes.paper}>
          <IconButton onClick={handleCancel} style={{ marginRight: 16 }}>
            <ArrowBack color="primary" />
          </IconButton>
          <div>
            <Typography color="primary" style={{ fontWeight: 'bold' }}>
              {state.register.storeName}
            </Typography>
            <Typography>{state.register.fullAddress}</Typography>
          </div>
        </Paper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBgQEPPBhJGNi0eDyhNyS-YHLyIucdzFPM' }}
          center={custom}
          defaultZoom={16}
          onClick={handleClickMap}
          options={{ fullscreenControl: false }}
        >
          <Marker lat={custom.lat} lng={custom.lng} />
        </GoogleMapReact>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#fff', zIndex: 2, justifyContent: 'center' }}>
        <Button variant="contained" autoFocus onClick={handleOpenWarning} color="primary">
          Salvar alterações e continuar
        </Button>
        <Button variant="contained" onClick={handleCancel}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import React, { useEffect } from 'react'
import { Button, Container, Grid, Typography, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link, useParams } from 'react-router-dom'
import Scroll from 'react-scroll'

import { ButtonHelp } from '../components'
import { Header } from '../Header'

import { useCustomer } from '../hooks/useCustomer'

import heroImg from '../assets/img-cel.png'
import benefitsImg1 from '../assets/benefitsImg1.svg'
import benefitsImg2 from '../assets/benefitsImg2.svg'
import benefitsImg3 from '../assets/benefitsImg3.svg'

import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'

const useStyles = makeStyles((theme) => ({
  hero: {
    position: 'relative',
    padding: '96px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 0 ',
    },
  },
  heroBox: {
    width: 430,
    maxWidth: '100%',
  },
  heroImg: {
    position: 'absolute',
    bottom: '-20px',
    right: '10%',
  },
  heroButton: {
    width: 248,
  },
  benefits: {
    padding: '48px 0',
    textAlign: 'center',
  },
  swiper: {
    height: '100%',
    width: '100%',
    paddingBlock: 24,

    '& .swiper-pagination': {
      bottom: 0,
    },

    '& span.swiper-pagination-bullet-active': {
      background: '#35920F',
    },
  },
}))

export default function Home({ isMobile }) {
  const classes = useStyles()
  const { hashkey } = useParams()
  const { customer, loadingCustomer, requestCustomer, removeCustomer } = useCustomer()
  let scroll = Scroll.animateScroll

  const handleScroll = () => scroll.scrollToBottom()

  useEffect(() => hashkey && !customer && requestCustomer(hashkey), [])

  if (!hashkey) removeCustomer()

  if (loadingCustomer) return <LinearProgress />

  return (
    <>
      <Header maxWidth="lg" />
      <div className={classes.hero}>
        <Container>
          <div className={classes.heroBox}>
            <Typography variant={isMobile ? 'h2' : 'h1'}>
              Ajude o consumidor a encontrar sua loja no Google!
            </Typography>
            <br />
            <Typography variant="body1">
              Contratamos a Gofind, nossa empresa parceira do Google Perfil da Empresa para ajudar a{' '}
              {customer?.name && `${customer?.name} a`} melhorar a presença digital da sua loja.
            </Typography>
            <br />
            {!isMobile && (
              <>
                <Button
                  className={classes.heroButton}
                  color="primary"
                  component={Link}
                  style={{ display: 'block', marginBottom: 8 }}
                  to="/v2/loja/registrar"
                  variant="contained"
                >
                  Cadastrar minha loja no Google!
                </Button>
                <Button
                  className={classes.heroButton}
                  color="primary"
                  variant="outlined"
                  onClick={handleScroll}
                >
                  Ver benefícios pra mim
                </Button>
              </>
            )}
          </div>
          {!isMobile && <img src={heroImg} alt="" className={classes.heroImg} />}
        </Container>
      </div>

      <div
        style={{
          textAlign: 'center',
          backgroundColor: isMobile ? '#FFF' : '#ECFFEE',
          padding: isMobile ? 0 : '48px 0',
        }}
      >
        <Container>
          {!isMobile && (
            <>
              <Typography variant="h2" gutterBottom>
                Benefícios
              </Typography>
              <Typography variant="body1">
                Veja alguns benefícios que essa parceria podem te trazer!
              </Typography>
              <br />
              <br />
            </>
          )}

          {isMobile ? (
            <Swiper modules={[Pagination]} loop pagination={{ clickable: true }} className={classes.swiper}>
              <SwiperSlide>
                <div>
                  <img src={benefitsImg1} alt="" />
                  <Typography className={classes.benefitsText}>
                    Sua loja aparecerá nas <br />
                    pesquisas por produtos <br />
                    {customer?.name?.split(' ')[0] || 'Gofind'}, na sua região.
                  </Typography>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src={benefitsImg2} alt="" />
                  <Typography className={classes.benefitsText}>
                    Vamos automatizar suas postagens, <br />
                    ou seja, menos trabalho para você e <br />
                    mais presença digital no Google.
                  </Typography>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src={benefitsImg3} alt="" />
                  <Typography className={classes.benefitsText}>
                    Vamos indicar os produtos da <br />
                    marca disponíveis na sua Loja <br />
                    para pessoas nas proximidades.
                  </Typography>
                </div>
              </SwiperSlide>
            </Swiper>
          ) : (
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                <img src={benefitsImg1} alt="" />
                <Typography className={classes.benefitsText}>
                  Sua loja aparecerá nas <br />
                  pesquisas por produtos <br />
                  {customer?.name || 'Gofind'},{customer?.name?.length > 10 && <br />} na sua região.
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <img src={benefitsImg2} alt="" />
                <Typography className={classes.benefitsText}>
                  Vamos automatizar suas postagens, <br />
                  ou seja, menos trabalho para você e <br />
                  mais presença digital no Google.
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <img src={benefitsImg3} alt="" />
                <Typography className={classes.benefitsText}>
                  Vamos indicar os produtos da <br />
                  marca disponíveis na sua Loja <br />
                  para pessoas nas proximidades.
                </Typography>
              </Grid>
            </Grid>
          )}
          <br />
          <br />

          <Button variant="contained" color="primary" component={Link} to="/v2/loja/registrar">
            Cadastrar minha loja no Google!
          </Button>
          <br />
          <br />
          {!isMobile && <ButtonHelp />}
          <br />
        </Container>
      </div>
    </>
  )
}

import React from 'react'
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core/'

export default function CustomCheckbox({ children, disabled, field, form, helperText }) {
  const error = form.errors[field.name]
  const helperTextValue = error || helperText

  return (
    <>
      <FormControlLabel
        style={{ margin: 'auto' }}
        control={
          <Checkbox
            color="primary"
            checked={!!field.value}
            style={{ display: 'inline' }}
            disabled={disabled || form.isSubmitting}
            inputProps={{ ...field }}
          />
        }
        label="Li e aceito os  "
      />
      {children}
      {helperTextValue && <FormHelperText error={!!error}>{helperTextValue}</FormHelperText>}
    </>
  )
}

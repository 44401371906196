import React from 'react'
import { withRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        color: '#444444',
      },
      h1: {
        fontSize: 32,
        fontWeight: 'bold',
      },
      h2: {
        fontSize: 24,
        fontWeight: '500',
      },
      h3: {
        fontSize: 18,
        fontWeight: '500',
      },
      h4: {
        fontSize: 16,
        fontWeight: '700',
        opacity: '.56',
        textTransform: 'uppercase',
      },
      h5: {
        fontSize: 16,
        fontWeight: '500',
      },
      h6: {
        fontSize: 14,
      },
      subtitle2: {
        fontSize: 16,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 20,
        textTransform: 'none',
      },
      contained: {
        backgroundColor: '#fff',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#F5F5F5',
      },
    },
  },

  typography: {
    useNextVariants: true,
  },
  palette: {
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#444444',
    },
    primary: {
      main: '#35920F',
    },
    secondary: {
      main: '#F5F5F5',
    },
  },
  color: '#444444',
})

function Theme({ children }) {
  window.theme = theme

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default withRouter(Theme)

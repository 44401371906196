/* eslint-disable */

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import marketStore from '../assets/markerStore.svg'

const diff = 40 / 30
const width = 28
const height = width * diff

const styles = () => ({
  img: {
    width,
    height,
    marginTop: -(height + 50),
    marginLeft: -width / 2,
    display: 'inline-block',
    zIndex: 90,
    transition: '.3s ease',
    position: 'relative',
    '&.tiny': {
      transform: 'scale(0.7)',
    },
    '&:hover, &.hover': {
      transform: 'scale(1.5)',
      zIndex: 250,
      '&:after': {
        content: '""',
        bottom: 0,
        left: width / 2,
        width: 5,
        height: 5,
        display: 'block',
        position: 'absolute',
        border: '1px solid red',
        boxShadow: '0 22px 35px',
      },
    },
  },
  shadow: {
    bottom: -5,
    left: 16,
    width: 24,
    height: 19,
    display: 'block',
    position: 'absolute',
    boxShadow: '0 22px 35px',
  },
})

const Store = ({ classes, hover, haveSelected, onClick }) => (
  <>
    <img
      src={marketStore}
      alt="store"
      className={cx(classes.img, { hover, tiny: !hover && haveSelected })}
      onClick={onClick}
    />
    {hover && <div className={classes.shadow} />}
  </>
)

export default withStyles(styles)(Store)

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import ButtonBase from '@material-ui/core/Button'
import googlesvg from './google-color.svg'

const useStyles = makeStyles(() => ({
  root: {
    background: '#4285F4',
    overflow: 'hidden',
    textTransform: 'initial',
    color: '#fff',
    boxShadow: '0 1px 1px rgba(33,33,33, .3)',
    borderRadius: 2,
    height: 46,
    paddingLeft: 34,
    minWidth: 240,

    '&:hover': {
      background: '#287ae6',
    },
  },
  iconContainer: {
    width: 50,
    height: 50,
    position: 'absolute',
    left: -2,
    top: -2,
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  text: {
    paddingLeft: 30,
    textAlign: 'left',
  },
}))

export default function GoogleButton({ onClick, loading, children }) {
  const classes = useStyles()

  return (
    <ButtonBase size="large" onClick={onClick} disabled={loading} className={classes.root}>
      <div className={classes.iconContainer}>
        <img src={googlesvg} alt="google icon" className={classes.icon} />
      </div>
      <span className={classes.text}>{loading ? 'entrando...' : children}</span>
    </ButtonBase>
  )
}

import React, { lazy, Suspense, useContext, useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import Auth, { AuthProvider } from './AuthCtx'
import Track from './Track'
import createStore from './store'

const Theme = lazy(() => import('./components/Theme'))
const AddStore = lazy(() => import('./pages/addStore/addStore'))
const ConfirmStore = lazy(() => import('./pages/addStore/ConfirmStore'))
const TermsOfService = lazy(() => import('./pages/extras/TermsOfService'))
const HomePage = lazy(() => import('./pages/home/home'))
const SnackProvider = lazy(() => import('~/snacks/SnackProvider'))
const Gofind = lazy(() => import('./Gofind'))
const GofindAdmin = lazy(() => import('./GofindAdmin'))
const StoreMain = lazy(() => import('./pages/store/StoreMain'))
const Admin = lazy(() => import('./pages/admin'))
const AdminUpdateToken = lazy(() => import('./pages/admin/AdminUpdateToken'))
const ListUsers = lazy(() => import('./pages/admin/ListUsers'))
const ListStores = lazy(() => import('./pages/admin/Lists'))
const SearchStores = lazy(() => import('./pages/admin/SearchStores'))
const LandingPage = lazy(() => import('./pages/landing'))

if (window.location.protocol === 'https:') {
  Sentry.init({ dsn: 'https://34187900d47f4f67bb287157af831378@o280122.ingest.sentry.io/1779441' })
}

function Logout() {
  const { logout } = useContext(Auth)
  logout()
  return null
}

const store = createStore()

function App() {
  useEffect(() => {
    Track('Page view', '')
  }, [])

  console.log('here?')

  return (
    <Router>
      <Suspense fallback="">
        <Provider store={store}>
          <SnackProvider>
            <AuthProvider>
              <Theme>
                <Switch>
                  <Route
                    path={['/', '/login', '/adicionar-loja']}
                    exact
                    render={props => <LandingPage {...props} />}
                  />
                  <Route path="/termos-de-servico" exact component={TermsOfService} />
                  <Route path="/logout" exact component={Logout} />
                  <Route path="/adicionar-loja" exact render={props => <AddStore {...props} isPublic />} />
                  <Route path="/confirmar-loja/:inviteHash" exact component={ConfirmStore} />
                  <Route path="/" exact render={() => <Redirect to="/app" />} />

                  <Route
                    path="/admin"
                    render={props => (
                      <GofindAdmin {...props}>
                        <Switch>
                          <Route path="/admin" exact component={Admin} />
                          <Route path="/admin/update-token" exact component={AdminUpdateToken} />
                          <Route path="/admin/list-users" component={ListUsers} />
                          <Route path={['/admin/list-stores', '/admin/stores']} component={ListStores} />
                          <Route path="/admin/search-stores" component={SearchStores} />
                          <Route render={() => '404'} />
                        </Switch>
                      </GofindAdmin>
                    )}
                  />

                  <Route
                    path="/app"
                    render={props => (
                      <Gofind {...props}>
                        <Switch>
                          <Route path="/app" exact component={HomePage} />
                          <Route path="/app/adicionar-loja" exact component={AddStore} />
                          <Route path="/app/loja/:cnpj" component={StoreMain} />
                          <Route render={() => '404'} />
                        </Switch>
                      </Gofind>
                    )}
                  />
                </Switch>
              </Theme>
            </AuthProvider>
          </SnackProvider>
        </Provider>
      </Suspense>
    </Router>
  )
}

export default App

import React from 'react'
import Async from 'react-select/async'
import cx from 'classnames'
import { makeStyles, useTheme, emphasize } from '@material-ui/core/styles'
import { Typography, TextField, Paper, MenuItem, Chip } from '@material-ui/core'
import PropTypes from 'prop-types'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    height: 'auto',
    padding: '15px 14px',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 16,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    // marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    top: 60,
  },
  divider: {
    height: theme.spacing(2),
  },
  chip: {
    // margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
}))

function MultiValue(props) {
  return (
    <Chip
      size="small"
      tabIndex={-1}
      color="primary"
      label={props.children}
      className={cx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick} // eslint-disable-line
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  const { error, label, helperText, classes, textFieldProps, variant } = props.selectProps
  return (
    <TextField
      fullWidth
      error={Boolean(error)}
      helperText={helperText || error}
      label={label}
      variant={variant}
      InputLabelProps={{ shrink: true }}
      // margin="dense"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function Menu(props) {
  return (
    <Paper elevation={6} square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  MultiValue,
  IndicatorsContainer: () => null,
}

function AsyncSelect({ onChange, initialValue, maxItems, ...props }) {
  const classes = useStyles()
  const theme = useTheme()
  const [single, setSingle] = React.useState(initialValue)

  const limitReached = single && single.length >= maxItems

  const handleChange = (value, { action }) => {
    if (action === 'select-option' && limitReached && !props.isMulti) {
      return
    }

    setSingle(value)
    onChange(value)
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  }

  return (
    <Async
      cacheOptions
      isMulti
      defaultValue
      {...props}
      classes={classes}
      styles={selectStyles}
      components={components}
      value={single}
      menuIsOpen
      isClearable={false}
      noOptionsMessage={({ inputValue }) => (inputValue ? `Sem resultados para: ${inputValue}` : null)}
      onChange={handleChange}
    />
  )
}

AsyncSelect.propTypes = {
  loadOptions: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxItems: PropTypes.number,
}

AsyncSelect.defaultProps = {
  placeholder: 'Digite para buscar',
  maxItems: 1,
}

export default AsyncSelect

import { handleActions } from 'redux-actions'
import actions from '~/actions'

const initialState = {}
const t = s => s && s.trim()

export function createDefaultStoreState(store) {
  return {
    cnpj: store.cnpj,
    raw: store,
    status: store.status || 'PENDING',
    gmbStatus: store.gmbStatus || '',
    products: {
      loading: false,
      fetched: false,
      items: [],
    },
    name: t(store.displayName) || t(store.placeName) || t(store.name),
    verify: {},
  }
}

const reducer = handleActions(
  {
    [actions.init]: (state, { payload: { stores } }) => {
      if (!stores) return state
      return {
        ...Object.entries(stores).reduce((acc, [key, curr]) => {
          return {
            ...acc,
            [key]: createDefaultStoreState(curr),
          }
        }, {}),
      }
    },

    [actions.updateStoreProducts]: (state, { payload }) => ({
      ...state,
      [payload.cnpj]: {
        ...state[payload.cnpj],
        products: payload,
      },
    }),

    [actions.updateStoreProductsShared]: (state, { payload: { cnpj, ...payload } }) => {
      return {
        ...state,
        [cnpj]: {
          ...state[cnpj],
          products: {
            ...state[cnpj]?.products,
            shared: { ...state[cnpj]?.products?.shared, ...payload },
          },
        },
      }
    },

    [actions.updateStoreVerify]: (state, { payload }) => ({
      ...state,
      [payload.cnpj]: {
        ...state[payload.cnpj],
        verify: {
          ...state[payload.cnpj].verify,
          ...payload,
        },
      },
    }),

    [actions.updateStore]: (state, { payload }) => ({
      ...state,
      [payload.cnpj]: createDefaultStoreState(payload),
    }),
  },
  initialState,
)

export default reducer

import React, { useContext, useState, useEffect } from 'react'
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  LinearProgress,
} from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { ButtonHelp, ButtonGoogleLogin } from '../components'
import { Header } from '../Header'
import Loading from '../components/Loading'
import feedback from '../assets/feedback.svg'
import AuthImage from '../assets/auth.svg'
import api from '~/utils/api'
import Auth from '~/AuthCtx'

import { useCustomer } from '../hooks/useCustomer'

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: 'calc(100vh - 80px)',
  },
  feedback: {
    padding: '20px 0',
  },
  dialog: {
    textAlign: 'center',
  },
  dialogInfo: {
    padding: 16,
    background: '#EEEBEB',
    display: 'block',
  },
})

export default function Feedback({ history, location, state, dispatch, isMobile }) {
  const classes = useStyles()
  const query = new URLSearchParams(location.search)
  const storeSentByMail = query.get('store')
  const hashkeySentByMail = query.get('hashkey')?.replace(/ /g, '+')

  const [authorization, setAuthorization] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingStore, setLoadingStore] = useState(false)
  const [leadIsIntegrated, setLeadIsIntegrated] = useState(false)

  const { loginGoogle, grantOfflineAccess } = useContext(Auth)
  const { customer, loadingCustomer, requestCustomer, removeCustomer } = useCustomer()

  const handleLoginGoogle = async () => {
    try {
      const auth = await loginGoogle()
      setAuthorization(true)
    } catch (error) {
      console.log('error loginGoogle', error)
    }
  }

  const handleAuth = async () => {
    try {
      const code = await grantOfflineAccess()
      const fullAddress = `${state.register.street}, ${state.register.number}, ${state.register.city}, ${state.register.state}`

      setLoading(true)

      if (code) {
        await api('/save-token', {
          method: 'post',
          data: { code },
        })
      }

      const results = await api(`/search-location-place?location=${state.register.storeName} ${fullAddress}`)
      dispatch({ ...results })

      if (!results.locations.length && !results.places.length) {
        return history.push('/v2/loja/criar')
      }

      if (results.locations.length > 0 || results.places.length > 0) {
        return history.push('/v2/loja/completar')
      }
    } catch (error) {
      console.log('TCL: ~ handleAuth ~ loginGoogle ~ error', error)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    if (!loading) {
      setAuthorization(false)
    }
  }

  useEffect(() => {
    if (hashkeySentByMail === 'gofind') {
      removeCustomer()
      return
    }

    if (hashkeySentByMail && !customer) requestCustomer(hashkeySentByMail, { query, name: 'hashkey' })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (storeSentByMail) {
        console.log('TCL: ~ ; ~ storeSentByMail', storeSentByMail)
        setLoadingStore(true)

        try {
          const { status, address, ...store } = await api(`/lead-status/${storeSentByMail}`, {
            disabledToken: true,
          })

          if (status === 'INTEGRATED') {
            setLeadIsIntegrated(true)
            return
          }

          const [street, number] = address.addressLine1.split(',')
          const {
            cnpj,
            name: storeName,
            publicPhone,
            publicEmail,
            responsiblePhone,
            responsibleEmail,
          } = store

          const values = {
            cnpj,
            storeName,
            publicPhone,
            publicEmail,
            responsiblePhone,
            responsibleEmail,
            street: street?.trim(),
            number: number?.trim(),
            ...address,
          }

          delete values.addressLine1

          dispatch({ type: 'register', ...values })
        } catch (err) {
          console.log('TCL: ~ useEffect ~ IIFE ~ err', err)
        } finally {
          setLoadingStore(false)

          query.delete('store')
          query.delete('hashkey')
          history.replace({ search: '' })
        }

        return
      }

      if (!state.register || !state.register.storeName) {
        history.push(`/v2/${customer?.hashkey || ''}`)
      }
    })()
  }, [])

  if (loadingStore || loadingCustomer) return <LinearProgress />

  if (leadIsIntegrated) return <Redirect to="/v2/loja/integrada" />

  return (
    <>
      <Header maxWidth="md" logoGofind={!customer} />
      <div className={classes.main}>
        <Dialog open={authorization} onClose={handleClose} fullWidth maxWidth="sm">
          {loading ? (
            <Loading />
          ) : (
            <>
              <DialogTitle>
                <Grid container justifyContent="space-between" alignItems="center">
                  Autorização
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </DialogTitle>
              <DialogContent className={classes.dialog}>
                <img src={AuthImage} alt="" />
                <Typography gutterBottom>
                  Para iniciar o cadastro, é preciso que você dê {!isMobile && <br />}
                  autorização à Gofind.
                </Typography>
                <Button variant="contained" color="primary" style={{ marginBottom: 16 }} onClick={handleAuth}>
                  Autorizar
                </Button>
                <Typography className={classes.dialogInfo} variant="caption">
                  Essa autorização nos permitirá postar produtos da sua marca parceira no seu Perfil da
                  Empresa do Google e também nos permitirá atualizar seu cadastro quando necessário,
                  tornando-o mais completo e atrativo nos resultados de busca.
                </Typography>
              </DialogContent>
            </>
          )}
        </Dialog>

        <Container className={classes.feedback}>
          <img src={feedback} alt="" />

          <Typography variant={isMobile ? 'h3' : 'h2'} component="h1">
            Sua inscrição foi enviada para análise!
          </Typography>
          <br />
          <Typography>Aguarde nosso e-mail de confirmação.</Typography>
          <br />
          <Typography>ou</Typography>
          <br />
        </Container>
        <div
          style={{
            padding: '20px 0',
            backgroundColor: isMobile ? '#E9E9E9' : '#ECFFEE',
            flex: 1,
          }}
        >
          <Container>
            <Typography variant={isMobile ? 'h5' : 'h3'} component="h2">
              Complete seu cadastro agora mesmo com a Gofind!
            </Typography>
            {!isMobile && (
              <>
                <br />
                <Typography>
                  Nossos consultores estão avaliando seus dados e isso pode levar algum tempo. Mas <br /> caso
                  você esteja com pressa e queira conectar sua loja agora mesmo, faça o login <br />
                  com a conta <strong>Gmail da sua loja</strong>.
                </Typography>
              </>
            )}
            <br />
            <ButtonGoogleLogin onClick={handleLoginGoogle}>
              LOGAR COM A CONTA {isMobile && <br />} GOOGLE DA LOJA
            </ButtonGoogleLogin>
            <br />
            <br />
            <ButtonHelp />
          </Container>
        </div>
      </div>
    </>
  )
}

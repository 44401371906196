import firebase2 from 'firebase/app'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyASbNyTVO7fx558Zd-ewgOd-0dfeM0oB4A',
  authDomain: 'gofind-gmb.firebaseapp.com',
  databaseURL: 'https://gofind-gmb.firebaseio.com',
  projectId: 'gofind-gmb',
  storageBucket: '',
  messagingSenderId: '468677221007',
}

firebase2.initializeApp(config)

export function authFirebase() {
  return window.TEST_MODE ? window.fakeAuth : firebase.auth()
}

export const firebase = firebase2

import React, { createContext, useCallback, useContext, useState, useRef, useEffect, useMemo } from 'react'
import * as Sentry from '@sentry/browser'

import { firebase, authFirebase } from './utils/firebase'

const context = createContext(null)
const googleProvider = new firebase.auth.GoogleAuthProvider()
const facebookProvider = new firebase.auth.FacebookAuthProvider()
authFirebase().languageCode = 'pt'

async function injectScript() {
  const script = document.createElement('script')
  script.src = 'https://apis.google.com/js/platform.js?onload=init'
  script.async = true
  script.defer = true

  return new Promise(resolve => {
    window.init = () => {
      window.gapi.load('auth2', () => {
        const auth2 = window.gapi.auth2.init({
          clientId: '468677221007-a8hnrfiu7cfu6vp6mdcqsgk5d45vrpqv.apps.googleusercontent.com',
          scope:
            'profile email https://www.googleapis.com/auth/plus.business.manage https://www.googleapis.com/auth/content https://www.googleapis.com/auth/structuredcontent',
        })
        resolve(auth2)
      })
    }
    document.head.appendChild(script)
  })
}

export function useAuth() {
  return useContext(context)
}

export function AuthProvider({ children }) {
  const { current: auth } = useRef(authFirebase())

  const [state, setState] = useState({
    initializing: true,
    darkMode: window.localStorage?.getItem('darkMode') === 'true' ? true : false,
  })

  const [auth2, setAuth2] = useState(null)

  useEffect(async () => {
    const res = await injectScript()
    setAuth2(res)
  }, [])

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      const isAdmin = user && user.email.includes('@gofind.online')
      setState(s => ({ ...s, user, isAdmin, initializing: false }))
      if (user) {
        window.mixpanel && window.mixpanel.identify(user.email)
        Sentry.configureScope(scope => {
          scope.setUser({ email: user.email })
        })
      }
    })
  }, [auth])

  const grantOfflineAccess = useCallback(async () => {
    const { code } = await auth2.grantOfflineAccess()
    return code
  }, [auth2])

  function toggleDarkMode() {
    setState(s => ({ ...s, darkMode: !s.darkMode }))
    window.localStorage?.setItem('darkMode', !state.darkMode)
  }

  const logout = useCallback(
    (redirect = true) => {
      console.log(redirect)
      auth.signOut()
      if (redirect) {
        window.location.href = '/'
      }
    },
    [auth],
  )

  const loginGoogle = useCallback(async () => {
    if (!firebase.auth().currentUser) {
      await auth.signInWithPopup(googleProvider)
    }
  }, [auth])

  const loginFacebook = useCallback(async () => {
    return auth.signInWithPopup(facebookProvider)
  }, [auth])

  const value = useMemo(
    () => ({
      auth,
      logout,
      loginGoogle,
      loginFacebook,
      ...state,
      grantOfflineAccess,
      toggleDarkMode,
    }),
    [state, auth, logout, loginGoogle, loginFacebook, grantOfflineAccess],
  )

  if (!auth2) return null
  return <context.Provider value={value}>{children}</context.Provider>
}

export default context

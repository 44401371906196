import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Container, Grid, IconButton, Paper, Radio, Tooltip, Typography } from '@material-ui/core'
import { StoreOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import DialogCompleteConfirm from './DialogCompleteConfirm'
import DialogCompleteUnrecognized from './DialogCompleteUnrecognized'
import CompleteThanks from './CompleteThanks'
import api from '~/utils/api'
import { useAuth } from '~/AuthCtx'

import { ButtonHelp } from '../../components'
import { Header } from '../../Header'
import ShowStore from '../../components/ShowStore'
import clover from '../../assets/clover.svg'

import { useCustomer } from '../../hooks/useCustomer'

const useStyles = makeStyles({
  clover: {
    display: 'block',
    margin: '24px auto',
  },
  selectStore: {
    marginBottom: 8,
    padding: 16,
    border: 'solid 1px #3ACC3B',
  },
  dialog: {
    '& $paper': {
      marginBottom: 8,
      padding: 12,
    },
    '& $selectStore': {
      padding: 12,
    },
  },
})

export default function Complete({ history, state, dispatch, isMobile }) {
  const classes = useStyles()
  const domain = window.location.toString()

  const [done, setDone] = useState(false)
  const [open, setOpen] = useState(false)
  const [unrecognized, setUnrecognized] = useState(false)
  const { customer } = useCustomer()

  const { user } = useAuth()

  const handleChange = event => {
    dispatch({ type: 'typeStore', value: 'place' })
    dispatch({ type: 'selectedValue', ...state.places[event.target.value] })
  }

  const handleChangeUsingLocation = event => {
    dispatch({ type: 'typeStore', value: 'location' })
    dispatch({ type: 'selectedValue', ...state.locations[event.target.value], isLocation: true })
  }

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const handleOpenUnrecognized = () => setUnrecognized(true)

  const handleCloseUnrecognized = () => setUnrecognized(false)

  const handleConfirm = async () => {
    console.log('TCL: ~ handleConfirm ~ state.selectedValue', state.selectedValue)

    if (state.typeStore === 'place' && state.selectedValue?.requestAdminRightsUri) {
      history.push('/v2/loja/email-invalido')
      return
    }

    if (state.typeStore === 'location') {
      try {
        const response = await api('/invite-location-gmb', {
          method: 'post',
          data: {
            locationId: state.selectedValue.name,
            cnpj: state.register.cnpj.replace(/\D/g, ''),
          },
        })

        if (!response.error) {
          await api('/send-email-status', {
            method: 'post',
            data: {
              step: 'INTEGRATED',
              customer,
              domain,
              emailTo: state.register.responsibleEmail,
              storeCnpj: state.register.cnpj.replace(/\D/g, ''),
            },
          })
        }
      } catch (err) {
        console.log('TCL: ~ handleClick ~ err', err)
      } finally {
        setLoading(false)
      }

      history.push('/v2/loja/sucesso')
      return
    }

    history.push('/v2/loja/completar-categoria')
  }

  const handleAlert = () => setDone(true)

  if (!state.register) {
    return <Redirect to={`/v2/${customer?.hashkey || ''}`} />
  }

  if ((!state.locations && !state.places) || !user) {
    return <Redirect to="/v2/loja/feedback" />
  }

  if (done) {
    return <CompleteThanks isMobile={isMobile} />
  }

  return (
    <>
      <Header maxWidth="md" logoGofind />
      <Container maxWidth="md">
        <DialogCompleteConfirm
          classes={classes}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          open={open}
          state={state}
          isMobile={isMobile}
        />
        <DialogCompleteUnrecognized
          classes={classes}
          handleAlert={handleAlert}
          handleCloseUnrecognized={handleCloseUnrecognized}
          unrecognized={unrecognized}
        />

        <img src={clover} alt="Trevo de 4 folhas" className={classes.clover} />

        <Typography variant="h2" component="h1" gutterBottom>
          Encontramos os resultados abaixo para sua loja no Google.{' '}
        </Typography>
        <Typography>
          Confirme abaixo se os resultados correspondem ao perfil da sua loja ou clique em "não reconheci
          nenhuma dessas lojas"
        </Typography>
        <br />

        <ShowStore
          title={state.register.storeName}
          subtitle={`${state.register.street}, ${state.register.number}, ${state.register.city}, ${state.register.state}`}
        />

        <Typography variant="caption" style={{ display: 'block', marginBottom: 16 }}>
          Selecione a loja referente as informações acima:
        </Typography>

        {state.places.map(
          ({ name, location: { title, storefrontAddress }, requestAdminRightsUri }, index) => (
            <Paper className={classes.selectStore} key={name + index} elevation={0}>
              <Grid container>
                <Grid item>
                  <Radio
                    checked={state.selectedValue?.name === name}
                    onChange={handleChange}
                    value={index}
                    name="radio"
                    color="primary"
                    size="small"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h5" color="primary">
                    {title}
                  </Typography>
                  <Typography variant="body2">{`${storefrontAddress.addressLines?.[0]}, ${storefrontAddress.locality}, ${storefrontAddress.administrativeArea}`}</Typography>
                </Grid>
                {requestAdminRightsUri ? (
                  <Grid item xs={1}>
                    <Tooltip title="Essa loja já possui um Perfil da Empresa." placement="top" arrow>
                      <IconButton>
                        <StoreOutlined />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid item xs={1}>
                    <IconButton disabled style={{ color: '#D0CFCF', backgroundColor: '#EEEBEB' }}>
                      <StoreOutlined />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Paper>
          ),
        )}

        {state.locations.map(({ title, name, storefrontAddress, requestAdminRightsUri }, index) => (
          <Paper className={classes.selectStore} key={name + index} elevation={0}>
            <Grid container>
              <Grid item>
                <Radio
                  checked={state.selectedValue?.name === name}
                  onChange={handleChangeUsingLocation}
                  value={index}
                  name="radio"
                  color="primary"
                  size="small"
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h5" color="primary">
                  {title}
                </Typography>
                <Typography variant="body2">{`${storefrontAddress.addressLines?.[0]}, ${storefrontAddress.locality}, ${storefrontAddress.administrativeArea}`}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Essa loja já possui um Perfil da Empresa." placement="top" arrow>
                  <IconButton>
                    <StoreOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Paper>
        ))}

        <Grid container justifyContent="space-between" style={{ marginTop: 16 }}>
          <Grid item {...(isMobile && { xs: 12 })}>
            <Button
              color="primary"
              variant="contained"
              fullWidth={isMobile}
              onClick={handleOpen}
              disabled={!state.selectedValue}
            >
              Confirmar Loja
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: isMobile ? 0 : 16, marginBlock: isMobile ? 8 : 0 }}
              fullWidth={isMobile}
              onClick={handleOpenUnrecognized}
            >
              Não reconheci nenhuma dessas lojas
            </Button>
          </Grid>
          <Grid item {...(isMobile && { xs: 12 })}>
            <ButtonHelp fullWidth={isMobile} style={{ marginBottom: isMobile ? 8 : 0 }} />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

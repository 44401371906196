import { handleActions } from 'redux-actions'
import actions from '~/actions'

const initialState = { selectedStore: null }

const reducer = handleActions(
  {
    [actions.init]: (state, { payload: { user } }) => {
      return { ...state, user, done: true }
    },
    [actions.selectStore]: (state, { payload }) => ({
      ...state,
      selectedStore: payload,
    }),
    [actions.updateApp]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [actions.restartApp]: state => ({
      ...state,
      done: false,
    }),
  },
  initialState,
)

export default reducer

import React, { useCallback, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Button, Container, Grid, Paper, Typography } from '@material-ui/core'

import { EditOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { ButtonHelp, Marker } from '../../components'
import { Header } from '../../Header'
import { useCustomer } from '../../hooks/useCustomer'
import GoogleMapReact from 'google-map-react'
import CreateDialogMap from './CreateDialogMap'

const useStyles = makeStyles(theme => ({
  container: {
    padding: 16,
    [theme.breakpoints.up('sm')]: {
      padding: '28px 0',
    },
  },
  paper: {
    margin: '24px 0',
    padding: 16,
    background: '#F5F5F5',
  },
}))

export default function CreateMap({ state, dispatch, isMobile }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [coordinates, setCoordinates] = useState({ lat: -14.235004, lng: -51.92528 })

  const { customer } = useCustomer()

  const handleApiLoaded = useCallback(google => {
    const geocoder = new google.maps.Geocoder()

    geocoder
      .geocode({ address: state.register.fullAddress })
      .then(result => {
        const coordinates = {
          lat: result.results[0].geometry.location.lat(),
          lng: result.results[0].geometry.location.lng(),
        }
        setCoordinates(coordinates)
        dispatch({
          type: 'updateRegister',
          latlng: { latitude: coordinates.lat, longitude: coordinates.lng },
        })
        return coordinates
      })
      .catch(e => {
        alert('Geocode was not successful for the following reason: ' + e)
      })
  }, [])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (!state.register) {
    return <Redirect to={`/v2/${customer?.hashkey || ''}`} />
  }

  return (
    <>
      <Header goBack={isMobile} maxWidth="md" logoGofind style={{ padding: isMobile ? '0 16px' : 0 }} />
      <Container maxWidth="md" className={classes.container}>
        <CreateDialogMap
          coordinates={coordinates}
          handleClose={handleClose}
          handleOpen={handleOpen}
          isMobile={isMobile}
          open={open}
          state={state}
          setCoordinates={setCoordinates}
        />

        <Grid item xs={12} sm={12}>
          <Typography variant="h2">2. Confirme seu endereço no mapa</Typography>
          <br />
          <Typography variant="body1">
            Caso o endereço no mapa esteja incorreto, será necessário ajustar no mapa.
          </Typography>
          <Paper className={classes.paper} elevation={0}>
            <Typography variant="h3" color="primary">
              {state.register.storeName}
            </Typography>
            <br />
            <Typography variant="body2">{state.register.fullAddress}</Typography>
          </Paper>

          <div style={{ height: 300, width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyBgQEPPBhJGNi0eDyhNyS-YHLyIucdzFPM' }}
              center={coordinates}
              defaultZoom={16}
              options={{
                fullscreenControl: false,
                draggable: false,
                draggableCursor: false,
                draggingCursor: false,
              }}
              onGoogleApiLoaded={handleApiLoaded}
              yesIWantToUseGoogleMapApiInternals
            >
              <Marker lat={coordinates.lat} lng={coordinates.lng} />
            </GoogleMapReact>
          </div>

          <Grid item container justifyContent="space-between" style={{ marginTop: 16 }} xs={12}>
            <Grid item xs={12} sm={8}>
              <Button
                fullWidth={isMobile}
                to="/v2/loja/criar-categoria"
                color="primary"
                variant="contained"
                component={Link}
                style={{ marginBottom: isMobile ? 8 : 0 }}
              >
                Meu endereço está correto
              </Button>
              <Button
                fullWidth={isMobile}
                variant="outlined"
                startIcon={<EditOutlined color="primary" />}
                style={{ marginLeft: isMobile ? 0 : 16, marginBottom: isMobile ? 8 : 0 }}
                onClick={handleOpen}
              >
                Ajustar no mapa
              </Button>
            </Grid>
            <Grid item {...(isMobile ? { xs: 12 } : null)}>
              <ButtonHelp fullWidth={isMobile} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import api from '~/utils/api'

export function useCustomer() {
  const history = useHistory()

  const [loadingCustomer, setLoadingCustomer] = useState(false)
  const [customer, setCustomer] = useState(() => {
    const customerStorage = sessionStorage.getItem('@gofind:customer')

    return customerStorage ? JSON.parse(customerStorage) : null
  })

  const requestCustomer = async (hashkey, queries = null) => {
    try {
      setLoadingCustomer(true)

      const response = await api(`/customer-logo?hashkey=${hashkey}`, {
        disabledToken: true,
      })

      setCustomer({ ...response, hashkey })
      sessionStorage.setItem('@gofind:customer', JSON.stringify({ ...response, hashkey }))
    } catch (err) {
      console.log('TCL: ~ requestCustomer ~ err', err)
    } finally {
      setLoadingCustomer(false)

      if (queries) {
        const { query, name } = queries

        query.delete(name)
        history.replace({ search: '' })
      }
    }
  }

  const removeCustomer = () => {
    const hasCustomer = sessionStorage.getItem('@gofind:customer')

    if (hasCustomer) {
      setCustomer(null)
      sessionStorage.removeItem('@gofind:customer')
    }
  }

  return { customer, loadingCustomer, requestCustomer, removeCustomer }
}

import React from 'react'
import Button from '@material-ui/core/Button'
import { HelpOutline } from '@material-ui/icons'

export default function ButtonHelp({ minWidth = 'none', ...props }) {
  return (
    <Button
      variant="outlined"
      startIcon={<HelpOutline color="primary" fontSize="small" />}
      style={{ backgroundColor: '#ffffff', minWidth }}
      {...props}
    >
      Precisa de ajuda?
    </Button>
  )
}

import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import RouterApp from './RouterApp'
import RouterV2 from './v2/Router'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/v2" component={RouterV2} />
        <Route path="/" component={RouterApp} />
      </Switch>
    </Router>
  )
}

export default App

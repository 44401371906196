import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Button, Container, Grid, Paper, Typography } from '@material-ui/core'
import { EditOutlined, RoomOutlined, PhoneOutlined, MailOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { ButtonHelp } from '../../components'
import { Header } from '../../Header'
import { useCustomer } from '../../hooks/useCustomer'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 16,
    [theme.breakpoints.up('sm')]: {
      padding: '28px 0',
    },
  },
  paper: {
    margin: '24px 0',
    padding: 16,
    background: '#F5F5F5',
  },
  paperText: {
    marginBottom: 8,
    '& svg': {
      verticalAlign: 'middle',
    },
  },
}))

export default function CreateAdress({ state, isMobile }) {
  const classes = useStyles()
  const { customer } = useCustomer()

  if (!state.register) {
    return <Redirect to={`/v2/${customer?.hashkey || ''}`} />
  }

  return (
    <>
      <Header maxWidth="md" logoGofind style={{ padding: isMobile ? '0 16px' : 0 }} />
      <Container maxWidth="md" className={classes.container}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h2">1. Confirme o endereço e contato para público!</Typography>
          <br />
          <Typography variant="body1">
            Lembre-se, são informações que serão exibidas para o público. Caso alguma dessas informações{' '}
            {!isMobile && <br />}
            deseje alterar, vá no botão "Editar dados".
          </Typography>

          <Paper className={classes.paper} elevation={0}>
            <Typography variant="h3" color="primary">
              {state.register.storeName}
            </Typography>
            <br />
            <Typography variant="body2" className={classes.paperText}>
              <RoomOutlined fontSize="small" /> {state.register.fullAddress}
            </Typography>
            <Typography variant="body2" className={classes.paperText}>
              <PhoneOutlined fontSize="small" />
              {state.register.responsiblePhone}
            </Typography>
            <Typography variant="body2" className={classes.paperText}>
              <MailOutlined fontSize="small" /> {state.register.responsibleEmail}
            </Typography>
          </Paper>

          <Grid item container justifyContent="space-between" style={{ marginTop: 16 }} xs={12}>
            <Grid item xs={12} sm={8}>
              <Button
                color="primary"
                component={Link}
                fullWidth={isMobile}
                to="/v2/loja/criar-mapa"
                variant="contained"
                style={{ marginBottom: isMobile ? 8 : 0 }}
              >
                Confirmar dados
              </Button>
              <Button
                component={Link}
                fullWidth={isMobile}
                startIcon={<EditOutlined color="primary" fontSize="small" />}
                style={{ marginLeft: isMobile ? 0 : 16 }}
                to="/v2/loja/criar-editar"
                variant="outlined"
              >
                Editar dados
              </Button>
            </Grid>
            {!isMobile && (
              <Grid item>
                <ButtonHelp />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

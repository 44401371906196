import { createStore, compose } from 'redux'

// import thunk from 'redux-thunk'
import rootReducer from './reducers/rootReducer'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

const enhancer = composeEnhancers()
// applyMiddleware(sagaMiddleware),
// other store enhancers if any

export default function configureStore(initialState = {}) {
  const store = createStore(rootReducer, initialState, enhancer)
  return store
}

import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Button, Container, Divider, Typography, Paper, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { FileCopyOutlined, Share, ArrowBack } from '@material-ui/icons'
import { ButtonHelp, ButtonGoogleLogin } from '../components'
import { Header } from '../Header'
import { useCustomer } from '../hooks/useCustomer'

import lock from '../assets/lock.svg'

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 80px)',
  },
  feedback: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 48px',
    gap: 16,

    '& > img': {
      alignSelf: 'center',
    },
  },
  extra: {
    padding: '28px 0',
    backgroundColor: '#F5F5F5',
    flex: 1,
  },
  link: {
    marginBottom: 8,
    padding: '20px',
    backgroundColor: '#EEEBEB',
    border: 'solid 1px #C4C4C4',
    maxWidth: '100%',
    width: 410,
    fontWeight: 'bold',
    borderRadius: 4,
  },
  storeCard: {
    width: 400,
    backgroundColor: '#EBE8E8',

    '& > div': { padding: '8px 16px' },
  },
  redirect: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    textDecoration: 'none',
    padding: '8px 16px',
    color: '#000',
  },
})

const handleCopy = () => {
  const link = document.getElementById('linkCopy').textContent

  navigator.clipboard.writeText(link)
}

const handleShare = () => {
  const linkText = document.getElementById('linkCopy').textContent
  // @todo
  navigator.share({
    title: 'link GMB(Google Perfil da Empresa)',
    url: linkText,
  })
}

export default function WrongGmb({ state }) {
  const classes = useStyles()
  const { customer } = useCustomer()

  if (!state.register) {
    return <Redirect to={`/v2/${customer?.hashkey || ''}`} />
  }

  return (
    <>
      <Header maxWidth="md" logoGofind />
      <div className={classes.main}>
        <Container maxWidth="md" className={classes.feedback}>
          <img src={lock} alt="Cadeado fechado" width="67" height="80" />

          <Typography variant="h2" component="h1">
            Opa, identificamos que sua loja já tem Perfil da Empresa no Google, <br />
            porém não está vinculado à esse Gmail.
          </Typography>

          <Paper className={classes.storeCard} elevation={0}>
            <Grid>
              <Typography variant="h5" color="primary">
                {state.register?.storeName}
              </Typography>
              <Typography variant="body2">{state.register?.fullAddress}</Typography>
            </Grid>
            <Divider style={{ width: '100%', height: 2 }} />
            <Link to="/v2/loja/completar" className={classes.redirect}>
              <ArrowBack color="primary" fontSize="small" />
              Voltar para lista de lojas
            </Link>
          </Paper>
        </Container>

        <div className={classes.extra}>
          <Container maxWidth="md" style={{ padding: '0 48px' }}>
            <Typography variant="h3">
              Não se preocupe, acontece. Para continuar, você tem essas opções:
            </Typography>
            <br />
            <Typography component="h2">
              <strong>1.</strong> Tentar novamente com outro Gmail. <br />
              <span style={{ fontSize: 13 }}>
                Às vezes essa conta foi criada por outra pessoa e ela utilizou outro e-mail. Se você souber,{' '}
                <br />
                pode tentar logar com o e-mail correto.
              </span>
            </Typography>
            <br />
            <ButtonGoogleLogin>LOGAR COM O GOOGLE</ButtonGoogleLogin>
            <br />
            <br />
            <Divider style={{ width: '100%' }} />
            <br />
            <Typography component="h2">
              <strong>2.</strong> Ou enviar o link abaixo para o responsável pela conta ativa no Google Perfil
              da Empresa.
            </Typography>
            <br />
            <div>
              <div className={classes.link} id="linkCopy">
                {`https://varejo.gofind.com.br/app/loja/${state.register?.cnpj || ''}`}
              </div>
              <Button color="primary" onClick={handleCopy}>
                <FileCopyOutlined color="primary" fontSize="small" /> Copiar Link
              </Button>
              <Button color="primary" onClick={handleShare}>
                <Share color="primary" fontSize="small" />
                Compartilhar
              </Button>
            </div>
            <br />
            <Divider style={{ width: '100%' }} />
            <br />

            <Typography component="h2">
              <b>3.</b> Ou aguardar nossa equipe entrar em contato para finalizar <br />
              sua conexão com a Gofind.
            </Typography>
            <br />
            <ButtonHelp />
          </Container>
        </div>
      </div>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { Button, Container, Grid, IconButton, Typography, LinearProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/styles'
import { ArrowBack, InfoOutlined } from '@material-ui/icons'
import { Formik, Form } from 'formik'
import { ButtonHelp, Info, Stepper } from '../../components'
import { Header } from '../../Header'
import { useCustomer } from '../../hooks/useCustomer'
import RegisterAddress from './RegisterAddress'
import RegisterStore from './RegisterStore'
import RegisterContact from './RegisterContact'
import api from '~/utils/api'

const useStyles = makeStyles((theme) => ({
  container: {
    width: 910,
    padding: '32px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 16,
    },
  },
  button: {
    marginRight: 8,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 8,
    },
  },
}))

const subtitles = [
  'Para começar, preencha as informações abaixo.',
  'Qual o endereço da sua loja?.',
  'Insira agora dados de contato do responsável e da loja.',
]

const infos = (storeName) => {
  return [
    `Insira aqui as informações sobre a loja que você pretende cadastrar no Google Perfil da Empresa. Estes dados são fundamentais para que a ${storeName} consiga gerenciar seu perfil.`,
    'Insira aqui o endereço da sua loja para que o Google informe aos consumidores onde encontrar seus produtos. Informar estes dados corretamente ajuda sua loja a ganhar mais destaque no Google.',
    `Estas informações ajudam o Google a saber que os dados fornecidos anteriormente são reais e permite que a ${storeName} salve seu contato para acessar o perfil da sua loja.`,
  ]
}

const validate = (values, step, editMode) => {
  const errors = {}

  if (step === 0 || editMode) {
    if (!values.cnpj || values.cnpj.replace(/[^\d]+/g, '').length !== 14)
      errors.cnpj = 'Por favor preencha esse campo corretamente.'
    if (!values.storeName) errors.storeName = 'Por favor preencha esse campo.'
  }

  if (step === 1 || editMode) {
    if (!values.zipcode || values.zipcode.replace(/[^\d]+/g, '').length !== 8)
      errors.zipcode = 'Por favor preencha esse campo.'
    if (!values.street) errors.street = 'Por favor preencha esse campo.'
    if (!values.number) errors.number = 'Por favor preencha esse campo.'
    if (!values.district) errors.district = 'Por favor preencha esse campo.'
    if (!values.state) errors.state = 'Por favor preencha esse campo.'
    if (!values.city) errors.city = 'Por favor preencha esse campo.'
  }

  if (step === 2 || editMode) {
    if (!values.responsiblePhone || values.responsiblePhone.replace(/[^\d]+/g, '').length < 10)
      errors.responsiblePhone = 'Por favor preencha esse campo.'
    if (!values.responsibleEmail) errors.responsibleEmail = 'Por favor preencha esse campo.'
    if (!editMode && !values.terms) errors.terms = 'Por favor leia e aceite os termos.'
  }

  return errors
}

export default function Register({ state, dispatch, history, location, isMobile }) {
  const classes = useStyles()
  const domain = window.location.toString()
  const editMode = location.pathname === '/v2/loja/criar-editar'
  const query = new URLSearchParams(location.search.toString())
  const hashkeySentByMail = query.get('hashkey')?.replace(/ /g, '+')

  const [infoOpen, setInfoOpen] = useState(false)
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const { customer, loadingCustomer, requestCustomer, removeCustomer } = useCustomer()

  const initialState = state.register || {}

  const handleSubmit = async (values) => {
    try {
      setLoading(true)

      const response = await api('/create-lead', {
        method: 'post',
        data: {
          ...values,
          customer: customer?.cnpj || '23809139000186',
          customerName: customer?.name || 'Gofind',
        },
        public: true,
      })

      if (!response.error) {
        await api('/send-email-status', {
          method: 'post',
          data: {
            step: 'REGISTERED',
            customer,
            domain,
            emailTo: values.responsibleEmail,
            storeCnpj: values.cnpj.replace(/\D/g, ''),
          },
        })
      }

      dispatch({ type: 'register', ...values })
      setLoading(false)

      if (editMode) return history.push('/v2/loja/criar-endereco')

      history.push('/v2/loja/feedback')
    } catch (error) {
      console.log('TCL: ~ Register ~ handleSubmit ~ error', error)
    } finally {
      setLoading(false)
    }
  }

  async function handleNext(validateForm, { cnpj }) {
    const errors = await validateForm()

    try {
      setLoading(true)

      const cnpjNum = cnpj.replace(/\D/g, '')
      const listStatus = ['PENDING_REVIEW', 'INTEGRATED', 'GOFIND_INVITED', 'DUPLICATED', 'DISCONNECTED']

      const { gmbStatus } = await api(`/verify-store-gmb-status?cnpj=${cnpjNum}`, { disabledToken: true })

      if (listStatus.includes(gmbStatus)) {
        setHasError(true)
        return
      }

      setHasError(false)
    } catch (error) {
      console.log('TCL: ~ handleNext ~ error', error)
    } finally {
      setLoading(false)
    }

    if (Object.keys(errors).length === 0) {
      setStep(step + 1)
    }
  }

  const handlePrev = (setErrors) => {
    setErrors({})
    setStep(step - 1)
  }

  const handleInfoOpen = () => {
    setInfoOpen(true)
  }

  useEffect(() => {
    if (hashkeySentByMail === 'gofind') {
      removeCustomer()
      return
    }

    if (hashkeySentByMail && !customer) requestCustomer(hashkeySentByMail, { query, name: 'hashkey' })
  }, [])

  if (loadingCustomer) return <LinearProgress />

  return (
    <>
      <Header goBack={isMobile && editMode} logoGofind={editMode} maxWidth="md" />
      <Container className={classes.container}>
        <Grid container spacing={isMobile ? 0 : 4}>
          <Grid item xs={12} sm={8}>
            {editMode && <Typography variant="h3">Editar dados</Typography>}
            <br />
            {!editMode && (
              <>
                <Stepper step={step} steps={['A loja', 'Endereço', 'Contato']} />
                <Typography variant="h6" style={{ margin: '24px 0' }}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8} sm={12}>
                      {subtitles[step]}
                    </Grid>
                    {isMobile && (
                      <Grid>
                        <IconButton size="small" onClick={handleInfoOpen}>
                          <InfoOutlined color="primary" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Typography>
              </>
            )}

            <Formik
              onSubmit={handleSubmit}
              initialValues={initialState}
              validate={(values) => validate(values, step, editMode)}
            >
              {(props) => (
                <Form>
                  {editMode && (
                    <Typography gutterBottom variant="h4">
                      A loja
                    </Typography>
                  )}
                  {(editMode || step === 0) && <RegisterStore />}
                  {editMode && (
                    <Typography gutterBottom variant="h4">
                      Endereço
                    </Typography>
                  )}
                  {(editMode || step === 1) && <RegisterAddress props={props} isMobile={isMobile} />}
                  {editMode && (
                    <Typography gutterBottom variant="h4">
                      Contato
                    </Typography>
                  )}
                  {(editMode || step === 2) && <RegisterContact editMode={editMode} isMobile={isMobile} />}

                  {hasError && <Alert severity="error">Já existe um registro neste cnpj!</Alert>}
                  <Grid container justifyContent="space-between" style={{ marginTop: 24 }}>
                    <div
                      style={{
                        width: isMobile ? '100%' : 'auto',
                      }}
                    >
                      {!editMode && step < 2 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleNext(props.validateForm, props.values)}
                          disabled={Object.keys(props.errors).length !== 0 || loading}
                          fullWidth={isMobile}
                          className={classes.button}
                        >
                          Continuar
                        </Button>
                      )}

                      {(step === 2 || editMode) && (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginRight: 8, marginBottom: isMobile ? 8 : 0 }}
                          onClick={props.handleSubmit}
                          fullWidth={isMobile}
                          disabled={loading}
                        >
                          {loading ? 'Enviando' : editMode ? 'Salvar alterações' : 'Enviar cadastro'}
                        </Button>
                      )}

                      {step > 0 && !isMobile && (
                        <Button
                          color="primary"
                          startIcon={<ArrowBack />}
                          onClick={() => handlePrev(props.setErrors)}
                          fullWidth={isMobile}
                        >
                          Voltar
                        </Button>
                      )}
                    </div>
                    <ButtonHelp fullWidth={isMobile} />
                    {isMobile && step > 0 && (
                      <Button
                        color="primary"
                        startIcon={<ArrowBack />}
                        onClick={() => handlePrev(props.setErrors)}
                        fullWidth={isMobile}
                        style={{ marginTop: 8 }}
                      >
                        Voltar
                      </Button>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          {!editMode && (
            <Grid item xs={12} sm={4} style={{ paddingTop: 80 }}>
              <Info
                text={infos(customer?.name?.split(' ')[0] || 'Gofind')[step]}
                open={infoOpen}
                setInfoOpen={setInfoOpen}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  )
}

import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { ButtonHelp } from '../../components'
import ShowStore from '../../components/ShowStore'

export default function DialogCompleteConfirm({
  classes,
  handleClose,
  handleConfirm,
  open,
  state,
  isMobile,
}) {
  if (!state.selectedValue) return null

  return (
    <Dialog
      aria-labelledby="dialog-confirm"
      className={classes.dialog}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle style={{ paddingBottom: 0 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          Confirmar loja
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        <Typography variant="body2" gutterBottom>
          Você confirma que esses dados se referem a loja que deseja <br />
          conectar à Gofind?
        </Typography>

        <ShowStore
          isDialog
          title={state.register.storeName}
          subtitle={`${state.register.street}, ${state.register.number}, ${state.register.city}, ${state.register.state}`}
        />

        <Typography variant="h6" gutterBottom>
          Essa loja se refere à essa:
        </Typography>

        {state.selectedValue.isLocation ? (
          <ShowStore
            isDialog
            className={classes.selectStore}
            title={state.selectedValue.title}
            subtitle={`${state.selectedValue.storefrontAddress.addressLines[0]}, ${state.selectedValue.storefrontAddress.locality}, ${state.selectedValue.storefrontAddress.administrativeArea}`}
          />
        ) : (
          <ShowStore
            isDialog
            className={classes.selectStore}
            title={state.selectedValue.location.title}
            subtitle={`${state.selectedValue.location.storefrontAddress.addressLines[0]}, ${state.selectedValue.location.storefrontAddress.locality}, ${state.selectedValue.location.storefrontAddress.administrativeArea}`}
          />
        )}
      </DialogContent>
      <DialogActions style={{ paddingBottom: 16 }}>
        <Grid container justifyContent="flex-end" style={{ gap: 8, padding: isMobile ? '0 16px' : 0 }}>
          <Grid item {...(isMobile && { xs: 12 })}>
            <Button
              variant="contained"
              fullWidth={isMobile}
              autoFocus
              onClick={handleConfirm}
              color="primary"
            >
              Confirmar loja
            </Button>
          </Grid>
          <Grid item {...(isMobile && { xs: 12 })}>
            <Button variant="contained" fullWidth={isMobile} onClick={handleClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item {...(isMobile && { xs: 12 })}>
            <ButtonHelp fullWidth={isMobile} />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  step: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 4,
    height: 3,
    width: 70,
    background: '#D6D9DB',

    '&.-active': {
      background: theme.palette.primary.main,
    },
  },
}))

export default function Stepper({ step, steps }) {
  const classes = useStyles()

  return (
    <>
      <Typography>
        <b>{step + 1}</b>. {steps[step]}
      </Typography>

      {steps.map((x, i) => (
        <div key={i} className={`${classes.step} ${i <= step ? '-active' : ''}`} />
      ))}
    </>
  )
}

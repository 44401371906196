import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

export default function DialogCompleteUnrecognized({
  classes,
  handleAlert,
  handleCloseUnrecognized,
  unrecognized,
}) {
  return (
    <Dialog
      aria-labelledby="dialog-unrecognized"
      className={classes.dialog}
      fullWidth
      maxWidth="sm"
      onClose={handleCloseUnrecognized}
      open={unrecognized}
    >
      <DialogTitle style={{ paddingBottom: 0 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          Não reconheci nenhuma dessas lojas
          <IconButton onClick={handleCloseUnrecognized}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        <Typography variant="body2" gutterBottom>
          Há a possibilidade de alguma destas lojas encontradas ter sido criada por um conhecido ou ex
          funcionário da sua loja. <br />
          <br />
          Deseja atualizar as informações de contato das lojas encontradas ou realmente não reconhece nenhum
          destes perfis como sendo da sua loja?
        </Typography>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 16 }}>
        <Button variant="contained" autoFocus onClick={handleAlert} color="primary">
          Não reconheço
        </Button>
        <Button variant="contained" onClick={handleCloseUnrecognized}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import React, { useReducer } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useMediaQuery } from '@material-ui/core'
import Theme from './Theme'
import { AuthProvider } from '~/AuthCtx'

import Home from './pages/Home'
import Register from './pages/Register/index'
import Feedback from './pages/Feedback'
import Create from './pages/Create/index'
import CreateAddress from './pages/Create/CreateAddress'
import CreateMap from './pages/Create/CreateMap'
import CreateCategory from './pages/Create/CreateCategory'
import Success from './pages/Success'
import WrongGmb from './pages/WrongGmb'
import Complete from './pages/Complete'
import StoreIntegrated from './pages/StoreIntegrated'

function reducer(state, { type, ...payload }) {
  if (type === 'register') {
    return {
      ...state,
      register: {
        ...payload,
        fullAddress: `${payload.street}, ${payload.number}, ${payload.city}, ${payload.state}`,
      },
    }
  }

  if (type === 'updateRegister') {
    return {
      ...state,
      register: {
        ...state.register,
        ...payload,
      },
    }
  }

  if (type === 'clearRegister') {
    return {
      ...state,
      register: {},
    }
  }

  if (type === 'typeStore') {
    return {
      ...state,
      typeStore: payload.value,
    }
  }

  if (type === 'selectedValue') {
    return {
      ...state,
      selectedValue: { ...payload },
    }
  }

  return { ...state, ...payload }
}

function App() {
  let { path } = useRouteMatch()
  const isMobile = useMediaQuery('(max-width:920px)')
  const [state, dispatch] = useReducer(reducer, {
    category: {
      displayName: 'Digite uma categoria...',
      categoryId: '',
    },
    typeStore: '',
    selectedValue: null,
    // register: {
    //   city: 'Uberlândia',
    //   cnpj: '40.285.554/0001-05',
    //   complement: '',
    //   district: 'Tibery',
    //   phone: '',
    //   publicEmail: '',
    //   publicPhone: '',
    //   responsibleEmail: 'wemersonnascimento52@gmail.com',
    //   responsiblePhone: '(34) 99106-2407',
    //   state: 'MG',
    //   storeName: 'Classe A',
    //   street: 'Rua Viena',
    //   terms: true,
    //   zipcode: '38405-086',
    //   number: '203',
    //   fullAddress: 'Rua Viena, 203, Uberlândia, MG',
    // },
    // locations: [
    //   {
    //     title: 'Classe A',
    //     name: 'accounts/112154324961567335068/locations/9595100793094806535',
    //     phoneNumbers: {
    //       primaryPhone: '(34) 99106-2407',
    //     },
    //     websiteUri: 'https://classe-a-bar.negocio.site/?utm_source=gmb&utm_medium=referral',
    //     // regularHours: {
    //     //   periods: [
    //     //     {
    //     //       openDay: 'SUNDAY',
    //     //       openTime: '12:00',
    //     //       closeDay: 'SUNDAY',
    //     //       closeTime: '24:00',
    //     //     },
    //     //     {
    //     //       openDay: 'MONDAY',
    //     //       openTime: '18:00',
    //     //       closeDay: 'MONDAY',
    //     //       closeTime: '22:00',
    //     //     },
    //     //     {
    //     //       openDay: 'TUESDAY',
    //     //       openTime: '18:00',
    //     //       closeDay: 'TUESDAY',
    //     //       closeTime: '22:00',
    //     //     },
    //     //     {
    //     //       openDay: 'WEDNESDAY',
    //     //       openTime: '18:00',
    //     //       closeDay: 'WEDNESDAY',
    //     //       closeTime: '22:00',
    //     //     },
    //     //     {
    //     //       openDay: 'THURSDAY',
    //     //       openTime: '18:00',
    //     //       closeDay: 'THURSDAY',
    //     //       closeTime: '22:00',
    //     //     },
    //     //     {
    //     //       openDay: 'FRIDAY',
    //     //       openTime: '12:00',
    //     //       closeDay: 'FRIDAY',
    //     //       closeTime: '24:00',
    //     //     },
    //     //     {
    //     //       openDay: 'SATURDAY',
    //     //       openTime: '12:00',
    //     //       closeDay: 'SATURDAY',
    //     //       closeTime: '24:00',
    //     //     },
    //     //   ],
    //     // },
    //     // locationKey: {
    //     //   placeId: 'ChIJNb0MyY1FpJQRzawhkCweUpk',
    //     //   requestId: '1634749413470',
    //     // },
    //     // labels: ['Heineken'],
    //     openInfo: {
    //       status: 'OPEN',
    //       canReopen: true,
    //     },
    //     // locationState: {
    //     //   isGoogleUpdated: true,
    //     //   canUpdate: true,
    //     //   canDelete: true,
    //     //   isVerified: true,
    //     //   isPublished: true,
    //     //   canHaveFoodMenus: true,
    //     // },
    //     // attributes: [
    //     //   {
    //     //     attributeId: 'has_curbside_pickup',
    //     //     valueType: 'BOOL',
    //     //     values: [false],
    //     //   },
    //     //   {
    //     //     attributeId: 'has_drive_through',
    //     //     valueType: 'BOOL',
    //     //     values: [false],
    //     //   },
    //     //   {
    //     //     attributeId: 'has_takeout',
    //     //     valueType: 'BOOL',
    //     //     values: [true],
    //     //   },
    //     //   {
    //     //     attributeId: 'serves_dine_in',
    //     //     valueType: 'BOOL',
    //     //     values: [true],
    //     //   },
    //     //   {
    //     //     attributeId: 'has_no_contact_delivery',
    //     //     valueType: 'BOOL',
    //     //     values: [false],
    //     //   },
    //     //   {
    //     //     attributeId: 'has_delivery',
    //     //     valueType: 'BOOL',
    //     //     values: [true],
    //     //   },
    //     // ],
    //     metadata: {
    //       mapsUri: 'https://maps.google.com/maps?cid=11047926012638768333',
    //       newReviewUri: 'https://search.google.com/local/writereview?placeid=ChIJNb0MyY1FpJQRzawhkCweUpk',
    //     },
    //     languageCode: 'pt',
    //     storefrontAddress: {
    //       regionCode: 'BR',
    //       languageCode: 'pt',
    //       postalCode: '38405-086',
    //       administrativeArea: 'MG',
    //       locality: 'Uberlândia',
    //       sublocality: 'Tibery',
    //       addressLines: ['R. Viena, 203'],
    //     },
    //   },
    // ],
    // places: [],
  })

  const defaultProps = { state, dispatch, isMobile }

  return (
    <AuthProvider>
      <Theme>
        <Switch>
          <Route
            exact
            path={[path, `${path}/:hashkey`]}
            render={(props) => <Home {...props} {...defaultProps} />}
          />
          <Route
            path={`${path}/loja/registrar`}
            render={(props) => <Register {...props} {...defaultProps} />}
          />
          <Route
            path={`${path}/loja/feedback`}
            render={(props) => <Feedback {...props} {...defaultProps} />}
          />
          <Route path={`${path}/loja/criar`} render={(props) => <Create {...props} {...defaultProps} />} />
          <Route
            path={`${path}/loja/criar-endereco`}
            render={(props) => <CreateAddress {...props} {...defaultProps} />}
          />
          <Route
            path={`${path}/loja/criar-mapa`}
            render={(props) => <CreateMap {...props} {...defaultProps} />}
          />
          <Route
            path={`${path}/loja/criar-categoria`}
            render={(props) => <CreateCategory {...props} {...defaultProps} />}
          />
          <Route
            path={`${path}/loja/criar-editar`}
            render={(props) => <Register {...props} {...defaultProps} />}
          />
          <Route
            path={`${path}/loja/completar`}
            render={(props) => <Complete {...props} {...defaultProps} />}
          />
          <Route
            path={`${path}/loja/completar-categoria`}
            render={(props) => <CreateCategory {...props} {...defaultProps} />}
          />
          <Route
            path={`${path}/loja/email-invalido`}
            render={(props) => <WrongGmb {...props} {...defaultProps} />}
          />
          <Route path={`${path}/loja/sucesso`} render={(props) => <Success {...props} {...defaultProps} />} />
          <Route
            path={`${path}/loja/integrada`}
            render={(props) => <StoreIntegrated {...props} {...defaultProps} />}
          />
        </Switch>
      </Theme>
    </AuthProvider>
  )
}

export default App

import React from 'react'
import { InputLabel, MenuItem, FormControl, Select, FilledInput, FormHelperText } from '@material-ui/core'

export default function CustomSelect({ disabled, field, form, helperText, label, options, ...props }) {
  const error = form.errors[field.name]
  const helperTextValue = error || helperText

  return (
    <FormControl error={!!error} variant="filled" style={{ width: '100%' }}>
      <InputLabel shrink variant="filled" htmlFor={name} style={{ color: '#35920F' }}>
        {label}
      </InputLabel>
      <Select
        {...props}
        value={field.value || ''}
        onChange={field.onChange}
        name={field.name}
        disabled={disabled}
        input={<FilledInput name={name} id={name} />}
        displayEmpty
      >
        <MenuItem key="" value="">
          Selecione...
        </MenuItem>

        {options &&
          options.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
      {helperTextValue && <FormHelperText>{helperTextValue}</FormHelperText>}
    </FormControl>
  )
}

import React from 'react'
import { Field } from 'formik'
import { Typography } from '@material-ui/core'
import { CustomField, CustomCheckbox } from '../../components'

export default function RegisterContact({ editMode }) {
  return (
    <>
      <Field
        id="responsiblePhone"
        name="responsiblePhone"
        label="Telefone do responsável"
        placeholder="xx-xxxxx-xxxx"
        component={CustomField}
        helperText="Telefone para o cadastro do responsável."
        isPhoneMask
      />
      <Field
        id="publicPhone"
        name="publicPhone"
        label="Telefone da loja (opcional)"
        placeholder="xx-xxxxx-xxxx"
        component={CustomField}
        helperText="Esse será o telefone visto publicamente."
        isPhoneMask
      />
      <Field
        id="responsibleEmail"
        name="responsibleEmail"
        label="E-mail responsável"
        placeholder="exemplo@gmail.com"
        component={CustomField}
        helperText="Cadastre o e-mail da pessoa responsável pela conta"
        type="email"
      />
      <Field
        id="publicEmail"
        name="publicEmail"
        label="E-mail da loja (opcional)"
        placeholder="exemplo@gmail.com"
        component={CustomField}
        helperText="Esse será o e-mail visto publicamente."
      />
      {!editMode && (
        <>
          <Field id="terms" name="terms" component={CustomCheckbox} label="Li e aceito os">
            <Typography style={{ display: 'inline' }}>
              {' '}
              <a href="#" target="_blank" style={{ color: '#121212' }}>
                termos de uso
              </a>
              {' e '}
              <a href="#" target="_blank" style={{ color: '#121212' }}>
                privacidade
              </a>
            </Typography>
          </Field>
        </>
      )}
    </>
  )
}

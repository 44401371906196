import React from 'react'
import { Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import feedback from '../../assets/feedback.svg'
import { Header } from '../../Header'

const useStyles = makeStyles({
  main: {
    textAlign: 'center',
  },
  feedback: {
    padding: '20px 16px',
  },
})

export default function CompleteThanks({ isMobile }) {
  const classes = useStyles()

  return (
    <>
      <Header maxWidth="md" logoGofind />
      <div className={classes.main}>
        <Container className={classes.feedback}>
          <img src={feedback} alt="" />

          <Typography variant="h2" component="h1">
            Sua inscrição foi enviada para análise!
          </Typography>
          <br />
          <Typography>
            Obrigada pela sua inscrição! Nossos consultores agora vão analisar seus dados e finalizar sua
            inscrição na parceria. {!isMobile && <br />}
            Em breve você receberá um e-mail de confirmação, fique atento!
          </Typography>
        </Container>
      </div>
    </>
  )
}

import React from 'react'
import TextField from '@material-ui/core/TextField'
import MaskedInput from 'react-text-mask'

function phoneMask(userInput) {
  let numbers = userInput.match(/\d/g)
  let numberLength = 0
  if (numbers) {
    numberLength = numbers.join('').length
  }

  if (numberLength > 10) {
    return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  } else {
    return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }
}

export default function CustomField({
  disabled,
  field,
  form: { errors, isSubmitting },
  helperText,
  mask,
  onChange,
  isPhoneMask,
  ...props
}) {
  const error = errors[field.name]
  const helperTextValue = error || helperText

  if (mask || isPhoneMask) {
    return (
      <MaskedInput
        {...props}
        {...field}
        disabled={disabled || isSubmitting}
        mask={isPhoneMask ? phoneMask : mask}
        onChange={disabled ? null : onChange ? onChange : field.onChange}
        style={{ marginBottom: 16 }}
        render={(ref, maskProps) => (
          <TextField
            error={Boolean(error)}
            FormHelperTextProps={{ style: { whiteSpace: 'break-spaces' } }}
            fullWidth
            helperText={helperTextValue}
            InputLabelProps={{ focused: true, shrink: true }}
            InputProps={{ inputRef: ref }}
            variant="filled"
            {...maskProps}
          />
        )}
      />
    )
  }

  return (
    <TextField
      {...props}
      {...field}
      disabled={disabled || isSubmitting}
      error={Boolean(error)}
      FormHelperTextProps={{ style: { whiteSpace: 'break-spaces' } }}
      fullWidth
      helperText={helperTextValue}
      InputLabelProps={{ focused: true, shrink: true }}
      onChange={disabled ? null : onChange ? onChange : field.onChange}
      style={{ marginBottom: 16 }}
      variant="filled"
    />
  )
}

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, IconButton, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Dashboard, Store, ArrowForwardIosRounded } from '@material-ui/icons'
import createFeedback from '../assets/createFeedback.svg'

import { Header } from '../Header'

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 80px)',
    textAlign: 'center',
  },
  feedback: {
    backgroundColor: '#fff',
    padding: '20px 0',
  },
  finish: {
    flex: 1,
    backgroundColor: '#B8FFDE',
    padding: '20px 0',
    textAlign: 'left',
  },
  extra: {
    marginBottom: 8,
    padding: 16,
  },
})

export default function StoreIntegrated({ dispatch, isMobile, location }) {
  const classes = useStyles()

  return (
    <>
      <Header maxWidth="md" logoGofind />
      <div className={classes.main}>
        <div className={classes.feedback}>
          <Container>
            <img src={createFeedback} alt="" />

            <Typography variant="h2" component="h1" gutterBottom>
              Opa! A loja em questão já foi integrada,
            </Typography>
            <Typography variant="subtitle2">
              A sua loja já está conectada com a Gofind. Agora é só aproveitar {!isMobile && <br />} nossos
              benefícios.
            </Typography>
            <br />
          </Container>
        </div>
        <div className={classes.finish}>
          <Container maxWidth="md">
            <Paper className={classes.extra} elevation={0}>
              <Grid container justifyContent="space-between" alignItems={isMobile ? 'center' : 'flex-start'}>
                <Store color="primary" style={{ marginRight: 16 }} />
                <Grid item xs={8}>
                  <Typography variant="h3">Crie um novo Perfil para outra loja</Typography>
                  {!isMobile && <Typography>Se você tem outras lojas, faça um novo cadastro.</Typography>}
                </Grid>
                {isMobile ? (
                  <IconButton
                    component={Link}
                    onClick={() => dispatch({ type: 'clearRegister' })}
                    to="/v2/loja/registrar"
                  >
                    <ArrowForwardIosRounded />
                  </IconButton>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginLeft: 'auto' }}
                    component={Link}
                    onClick={() => dispatch({ type: 'clearRegister' })}
                    to="/v2/loja/registrar"
                  >
                    Criar novo Perfil
                  </Button>
                )}
              </Grid>
            </Paper>

            <Paper className={classes.extra} elevation={0}>
              <Grid container justifyContent="space-between" alignItems={isMobile ? 'center' : 'flex-start'}>
                <Dashboard color="primary" style={{ marginRight: 16 }} />
                <Grid item xs={8}>
                  <Typography variant="h3">Acesse seu painel do Varejo Gofind</Typography>
                  {!isMobile && (
                    <Typography>
                      Mantenha suas informações atualizadas para que possamos publicar produtos e trazer mais
                      visualizações para sua loja
                    </Typography>
                  )}
                </Grid>
                {isMobile ? (
                  <IconButton comp component="a" href="https://varejo.gofind.com.br/" target="_blank">
                    <ArrowForwardIosRounded />
                  </IconButton>
                ) : (
                  <Button
                    color="primary"
                    component="a"
                    href="https://varejo.gofind.com.br/"
                    size="large"
                    style={{ alignSelf: 'center', marginLeft: 'auto' }}
                    variant="outlined"
                    target="_blank"
                  >
                    Acessar
                  </Button>
                )}
              </Grid>
            </Paper>
          </Container>
        </div>
      </div>
    </>
  )
}

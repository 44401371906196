import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography, Popover, IconButton, useMediaQuery } from '@material-ui/core'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '~/AuthCtx'
import { useCustomer } from './hooks/useCustomer'

import newGofindLogo from './assets/new-logo-gofind.svg'

const useStyles = makeStyles((theme) => ({
  header: {
    height: 80,
    background: '#F5F5F5',
  },
  logo: {
    width: 400,
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 240,
    },
  },
  logout: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: 8,
  },
  logoGmb: {
    display: 'flex',
    alignItems: 'center',
  },
  textGmb: {
    fontSize: 20,
    fontWeight: 500,
    color: '#5F6368',
    borderLeft: '1px solid #70707020',
    paddingLeft: 8,
  },
}))

export function Header({ goBack, maxWidth, logoGofind, ...props }) {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = useMediaQuery('(max-width:920px)')

  const { customer } = useCustomer()
  const { user, logout } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div className={classes.header}>
      <Container maxWidth={maxWidth} {...props}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          style={{ height: 80 }}
        >
          {goBack && (
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackIcon />
            </IconButton>
          )}

          {logoGofind || !customer ? (
            <Link to={`/v2/${customer?.hashkey || ''}`} style={{ textDecoration: 'none', marginTop: 8 }}>
              <img src={newGofindLogo} alt="gofind" width={100} />
            </Link>
          ) : (
            <Link to={`/v2/${customer?.hashkey}`} style={{ textDecoration: 'none' }}>
              <img src={customer?.logo} alt={customer?.name} width={100} loading="lazy" />
            </Link>
          )}

          {user && !isMobile ? (
            <Typography variant="body2">
              {user.email}
              <b onClick={() => logout(false)} className={classes.logout}>
                Sair
              </b>
            </Typography>
          ) : user && isMobile ? (
            <>
              <IconButton aria-describedby={id} onClick={handleClick}>
                <AccountCircleOutlinedIcon color="primary" />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography style={{ padding: 16, width: '100%' }}>
                  {user.email}
                  <span onClick={() => logout(false)} style={{ color: '#35920F', marginLeft: 64 }}>
                    Sair
                  </span>
                </Typography>
              </Popover>
            </>
          ) : (
            <>
              <div className={classes.logoGmb}>
                <img
                  src={newGofindLogo}
                  alt="gofind"
                  style={{ marginRight: 12, marginTop: 3 }}
                  width={isMobile ? 50 : 100}
                />
                <span className={classes.textGmb}>Google Perfil da Empresa</span>
              </div>
            </>
          )}
        </Grid>
      </Container>
    </div>
  )
}

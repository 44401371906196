import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'

export default function ShowStore({ title, subtitle, className, isDialog }) {
  return (
    <Paper
      style={
        className
          ? null
          : {
              marginBottom: 24,
              padding: 16,
              backgroundColor: '#F5F5F5',
            }
      }
      elevation={0}
      className={className}
    >
      <Typography variant="h3" color="primary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
        {!isDialog && <RoomOutlinedIcon style={{ fontSize: 16, marginRight: 8, color: '#BCBCBC' }} />}
        {subtitle}
      </Typography>
    </Paper>
  )
}

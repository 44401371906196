import React from 'react'
import { Button, Container, Typography } from '@material-ui/core'
import { ButtonHelp } from '../../components'
import Clover from '../../assets/clover.svg'
import { Link } from 'react-router-dom'
import { Header } from '../../Header'

export default function Create({ isMobile }) {
  return (
    <>
      <Header maxWidth="md" logoGofind />
      <Container style={{ padding: '28px 8px', textAlign: 'center' }}>
        <img src={Clover} alt="" />
        <Typography variant="h1">Vamos criar sua loja!</Typography>
        <Typography variant="body1">
          No próximos passos, vamos confirmar algumas informações e {!isMobile && <br />}
          tornar seu perfil mais atrativo aos seus clientes.
        </Typography>
        <br />
        <Button
          to="/v2/loja/criar-endereco"
          color="primary"
          variant="contained"
          component={Link}
          fullWidth={isMobile}
          style={{ minWidth: 180 }}
        >
          Continuar
        </Button>
        <br />
        <br />
        <ButtonHelp fullWidth={isMobile} minWidth={180} />
      </Container>
    </>
  )
}

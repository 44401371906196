import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, CircularProgress, Container, Grid, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import { ButtonHelp } from '../../components'
import { Header } from '../../Header'
import { useCustomer } from '../../hooks/useCustomer'
import AsyncSelect from '~/components/Select/Async'

import api from '~/utils/api'

const useStyles = makeStyles(theme => ({
  container: {
    padding: 16,
    [theme.breakpoints.up('sm')]: {
      padding: '28px 0',
    },
  },
  formControl: {
    margin: 8,
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: 16,
  },
}))

export default function CreateCategory({ state, dispatch, isMobile, ...rest }) {
  const classes = useStyles()
  const domain = window.location.toString()

  const [loading, setLoading] = useState(false)
  const { customer } = useCustomer()

  const handleClick = async () => {
    setLoading(true)

    try {
      const response = await api('/integrate-gmb', {
        method: 'post',
        data: {
          cnpj: state.register.cnpj.replace(/\D/g, ''),
          ...(state.selectedValue
            ? {
                location: {
                  ...state.selectedValue.location,
                  categories: { primary_category: state.category },
                  languageCode: 'pt',
                },
              }
            : {
                location: {
                  title: state.register.storeName,
                  languageCode: 'pt',
                  phoneNumbers: { primaryPhone: state.register.responsiblePhone },
                  categories: { primary_category: state.category },
                  storefrontAddress: {
                    regionCode: 'BR',
                    postalCode: state.register.zipcode,
                    administrativeArea: state.register.state,
                    locality: state.register.city,
                    addressLines: [state.register.fullAddress],
                    sublocality: state.register.district,
                  },
                  latlng: { ...state.register.latlng },
                },
              }),
        },
      })

      if (!response.error) {
        await api('/send-email-status', {
          method: 'post',
          data: {
            step: 'INTEGRATED',
            customer,
            domain,
            emailTo: state.register.responsibleEmail,
            storeCnpj: state.register.cnpj.replace(/\D/g, ''),
          },
        })
      }

      setLoading(false)
      history.push('/v2/loja/sucesso')
    } catch (err) {
      console.log('TCL: ~ handleClick ~ err', err)
    } finally {
      setLoading(false)
    }
  }

  const handleUpdate = newValue => {
    dispatch({ category: newValue })
  }

  const searchCategory = (term, callback) => {
    api(`/search-categories?searchTerm=${term}`).then(response => {
      const {
        categories: { categories },
      } = response
      callback(categories)
    })
  }

  if (!state.register) {
    return <Redirect to={`/v2/${customer?.hashkey || ''}`} />
  }

  return (
    // @todo loading call loading
    <>
      <Header goBack={isMobile} maxWidth="md" logoGofind style={{ padding: isMobile ? '0 16px' : 0 }} />
      <Container maxWidth="md" className={classes.container}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2">3. Escolha uma categoria para sua loja.</Typography>
          <br />
          <Typography variant="body1" gutterBottom>
            O Google dá preferência para lojas que informam sua categoria. Informe abaixo qual é o tipo do seu
            negócio.
          </Typography>
          <br />

          <AsyncSelect
            initialValue={state.category}
            onChange={handleUpdate}
            name="category"
            label=""
            variant="filled"
            loadOptions={searchCategory}
            getOptionLabel={i => i.displayName}
            getOptionValue={i => i.categoryId}
            isMulti={false}
          />

          <Typography variant="caption">
            Informe o tipo do seu negócio. Ex.: Açougue, Restaurante Italiano
          </Typography>

          <Grid item container justifyContent="space-between" style={{ marginTop: 16 }} xs={12}>
            <Grid item>
              <Button
                onClick={handleClick}
                color="primary"
                variant="contained"
                fullWidth={isMobile}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Sim, continuar'}
              </Button>
            </Grid>
            <Grid item>
              <ButtonHelp />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

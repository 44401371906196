import React from 'react'
import { Dialog, DialogContent, Grid, DialogContentText, Typography, useMediaQuery } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  info: {
    padding: 32,
    backgroundColor: '#ECFFEE',
  },
  infoIcon: {
    position: 'absolute',
    top: 4,
    left: -20,
    fontSize: 16,
  },
})

export default function Info({ text, open, setInfoOpen }) {
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width:920px)')

  const handleClose = () => {
    setInfoOpen(false)
  }

  if (isMobile) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Grid container>
            <Grid item xs={2}>
              <InfoOutlined color="primary" />
            </Grid>
            <Grid item xs={10}>
              <DialogContentText>{text}</DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <div className={classes.info}>
      <Typography variant="body2" style={{ position: 'relative' }}>
        <InfoOutlined className={classes.infoIcon} color="primary" />
        {text}
      </Typography>
    </div>
  )
}

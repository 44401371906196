import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'formik'
import { CustomField, CustomSelect } from '../../components'
import api from '~/utils/api'

const states = [
  { id: 'AC', label: 'Acre' },
  { id: 'AL', label: 'Alagoas' },
  { id: 'AP', label: 'Amapá' },
  { id: 'AM', label: 'Amazonas' },
  { id: 'BA', label: 'Bahia' },
  { id: 'CE', label: 'Ceará' },
  { id: 'DF', label: 'Distrito Federal' },
  { id: 'ES', label: 'Espírito Santo' },
  { id: 'GO', label: 'Goías' },
  { id: 'MA', label: 'Maranhão' },
  { id: 'MT', label: 'Mato Grosso' },
  { id: 'MS', label: 'Mato Grosso do Sul' },
  { id: 'MG', label: 'Minas Gerais' },
  { id: 'PA', label: 'Pará' },
  { id: 'PB', label: 'Paraíba' },
  { id: 'PR', label: 'Paraná' },
  { id: 'PE', label: 'Pernambuco' },
  { id: 'PI', label: 'Piauí' },
  { id: 'RJ', label: 'Rio de Janeiro' },
  { id: 'RN', label: 'Rio Grande do Norte' },
  { id: 'RS', label: 'Rio Grande do Sul' },
  { id: 'RO', label: 'Rondônia' },
  { id: 'RR', label: 'Roraíma' },
  { id: 'SC', label: 'Santa Catarina' },
  { id: 'SP', label: 'São Paulo' },
  { id: 'SE', label: 'Sergipe' },
  { id: 'TO', label: 'Tocantins' },
]

export default function Address({ props, isMobile }) {
  const [searchCep, setSearchCep] = useState('')

  async function handleCep(event, setFieldValue) {
    const value = event.target.value
    setFieldValue('zipcode', event.target.value)
    setSearchCep(value)
  }

  useEffect(() => {
    ;(async () => {
      if (!searchCep) return
      if (searchCep.length === 9 && !isNaN(parseInt(searchCep.substr(-1)))) {
        try {
          const request = await api(`https://viacep.com.br/ws/${searchCep}/json/`, { public: true })
          const { bairro, complemento, localidade, logradouro, uf } = request
          if (!uf) return
          props.setFieldValue('street', logradouro)
          props.setFieldValue('district', bairro)
          props.setFieldValue('complement', complemento)
          props.setFieldValue('state', uf)
          props.setFieldValue('city', localidade)

          setSearchCep('')
        } catch (e) {
          console.log(e)
        }
      }
    })()
  }, [searchCep])

  return (
    <>
      <Field
        id="zipcode"
        name="zipcode"
        label="CEP"
        component={CustomField}
        mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/]}
        onChange={event => handleCep(event, props.setFieldValue)}
        placeholder="00000-000"
      />
      <Field
        id="street"
        name="street"
        label="Rua ou avenida"
        placeholder="digite aqui a rua ou avenida"
        component={CustomField}
      />
      <Grid container {...(!isMobile ? { spacing: 4 } : null)}>
        <Grid item xs={12} sm={6}>
          <Field id="number" name="number" label="Número" placeholder="0" component={CustomField} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            id="district"
            name="district"
            label="Bairro"
            placeholder="Digite aqui..."
            component={CustomField}
          />
        </Grid>
      </Grid>
      <Field
        id="complement"
        name="complement"
        label="Complemento (opcional)"
        placeholder="Exemplo: Empresarial x, sala x"
        component={CustomField}
      />
      <Grid container {...(!isMobile ? { spacing: 4 } : null)}>
        <Grid item xs={12} sm={6} style={{ marginBottom: isMobile && 16 }}>
          <Field id="state" name="state" label="Estado" component={CustomSelect} options={states} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field id="city" name="city" label="Cidade" component={CustomField} />
        </Grid>
      </Grid>
    </>
  )
}

import { useDispatch } from 'react-redux'
import { createActions } from 'redux-actions'
import { bindActionCreators } from 'redux'
import { useMemo, useCallback } from 'react'

export function useActions(actions) {
  const dispatch = useDispatch()

  if (typeof actions === 'function') {
    return useCallback(action => dispatch(actions(action)), [dispatch])
  }

  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch])
}

export default createActions({
  init: undefined,
  selectStore: undefined,
  updateApp: undefined,
  updateStore: undefined,
  updateStoreProducts: undefined,
  updateStoreProductsShared: undefined,
  updateStoreVerify: undefined,
  restartApp: undefined,
  updateStoreKey: undefined,
})

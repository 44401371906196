import React from 'react'
import { Field } from 'formik'
import { CustomField } from '../../components'

export default function RegisterContact() {
  return (
    <>
      <Field
        component={CustomField}
        id="cnpj"
        label="CNPJ"
        name="cnpj"
        placeholder="00.000.000/0000-00"
        mask={[
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
        ]}
      />
      <Field
        component={CustomField}
        id="storeName"
        label="Nome da loja"
        name="storeName"
        placeholder="digite o nome da loja"
      />
    </>
  )
}

import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import loading from '../assets/loading.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    animation: `$fadeIn 1000ms ${theme.transitions.easing.easeInOut}`,

    zIndex: 9999,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#3ACC3B',
    paddingTop: 120,
    textAlign: 'center',
    color: '#fff',
  },

  dotsContainer: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: 60,
  },

  dots: {
    position: 'relative',
    width: '5px',
    height: '5px',
    borderRadius: '5px',
    backgroundColor: '#fff',
    color: '#fff',
    animation: '$dotElastic 1s infinite linear',
    margin: 'auto',

    '&:before': {
      content: '" "',
      display: 'inline - block',
      position: 'absolute',
      top: 0,
      left: '-15px',
      width: '5px',
      height: '5px',
      borderRadius: '5px',
      backgroundColor: '#fff',
      color: '#fff',
      animation: '$dotElasticBefore 1s infinite linear',
    },

    '&:after': {
      content: '" "',
      display: 'inline - block',
      position: 'absolute',
      top: 0,
      left: '15px',
      width: '5px',
      height: '5px',
      borderRadius: '5px',
      backgroundColor: '#fff',
      color: '#fff',
      animation: '$dotElasticAfter 1s infinite linear',
    },
  },

  '@keyframes dotElasticBefore': {
    '0% ': {
      transform: 'scale(1, 1)',
    },
    '25%': {
      transform: 'scale(1, 1.5)',
    },
    '50%': {
      transform: 'scale(1, 0.67)',
    },
    '75%': {
      transform: 'scale(1, 1)',
    },
    '100%': {
      transform: 'scale(1, 1)',
    },
  },

  '@keyframes dotElastic': {
    '0%': {
      transform: 'scale(1, 1)',
    },
    '25%': {
      transform: 'scale(1, 1)',
    },
    '50%': {
      transform: 'scale(1, 1.5)',
    },
    '75%': {
      transform: 'scale(1, 1)',
    },
    '100%': {
      transform: 'scale(1, 1)',
    },
  },

  '@keyframes dotElasticAfter': {
    '0%': {
      transform: 'scale(1, 1)',
    },
    '25%': {
      transform: 'scale(1, 1)',
    },
    '50%': {
      transform: 'scale(1, 0.67)',
    },
    '75%': {
      transform: 'scale(1, 1.5)',
    },
    '100%': {
      transform: 'scale(1, 1)',
    },
  },

  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}))

export default function Loading() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        color="inherit"
        style={{ position: 'relative', marginBottom: 16, fontWeight: 400 }}
      >
        Verificando cadastros no <br />
        <b style={{ fontWeight: 500 }}>
          Google Perfil da Empresa...
          <div className={classes.dotsContainer}>
            <div className={classes.dots}></div>
          </div>
        </b>
      </Typography>
      <img src={loading} alt="" />
    </div>
  )
}

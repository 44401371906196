import axios from 'axios'
import * as deepmerge from 'deepmerge'
import { authFirebase } from './firebase'

let prefix = 'http://localhost:5001/gofind-gmb/us-central1/app'

if (!window.location.href.includes('local')) {
  prefix = process.env.BACKEND_URL
}

// if (true) {
//   prefix = 'https://my-json-server.typicode.com/guiphc/retailApi'
// }

const handleCatch = ({ response }) => {
  if (!response) {
    throw new Error('db_error')
  }
  if (response.status === 400) {
    throw response.data
  }

  throw response
}

function handleApi() {
  const data = { current: {} }

  authFirebase().onIdTokenChanged(async user => {
    data.current.user = user
  })

  return async (url, options = {}) => {
    let token
    const fullUrl = `${url.includes('http') ? '' : prefix}${url}`

    if (!options.public && !options.disabledToken) {
      token = options.access_token || (await data.current.user.getIdToken())
    }

    if (options.upload && !options.disabledToken) {
      return axios
        .post(fullUrl, options.data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(handleCatch)
    }
    let request = {
      ...options,
      cors: true,
      url: fullUrl,
    }

    if (!options.public && !options.disabledToken) {
      request = deepmerge(request, {
        headers: { Authorization: `Bearer ${token}`, ...options.headers },
      })
    }

    return axios(request)
      .then(res => res.data)
      .catch(handleCatch)
  }
}

export default handleApi()
